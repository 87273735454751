import React from 'react';
import { useQuery } from '@apollo/client';
import { INVOICE_DEBTOR_SUMMARY } from '@fingo/lib/graphql';
import { MasterEntityType } from '@fingo/lib/propTypes';
import { useSelectedCompany } from '@fingo/lib/hooks';
import DebtorSummaryStatisticsGrid from '@fingo/lib/features/debtor/DebtorSummaryStatisticsGrid';

const DebtorSummaryStatistics = ({ debtor }) => {
  const selectedCompany = useSelectedCompany();
  const { data, loading } = useQuery(INVOICE_DEBTOR_SUMMARY, {
    variables: { ownerId: selectedCompany?.id, debtorId: debtor?.id },
    skip: !debtor || !selectedCompany,
  });

  const {
    currentDebtAmount,
    badDebtAmount,
    averagePaymentTerm,
    collectedInvoices,
    lateCollectedInvoices,
  } = Object.values(data || [])?.[0] || {};

  const debtorType = debtor?.debtor?.classification.code;

  return (
    <DebtorSummaryStatisticsGrid
      debtorType={debtorType}
      averagePaymentTerm={averagePaymentTerm}
      currentDebtAmount={currentDebtAmount}
      loading={loading}
      lateCollectedInvoices={lateCollectedInvoices}
      badDebtAmount={badDebtAmount}
      collectedInvoices={collectedInvoices}
    />

  );
};

DebtorSummaryStatistics.propTypes = {
  debtor: MasterEntityType.isRequired,
};

export default DebtorSummaryStatistics;

import { string as yupString, object as yupObject } from 'yup';

export const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
};

export const VALIDATION_SCHEMA = yupObject({
  firstName: yupString().required('El nombre es obligatorio'),
  lastName: yupString().required('El apellido es obligatorio'),
  phoneNumber: yupString()
    .matches(/^\+?\d{9,}$/, 'Número de teléfono inválido')
    .required('El teléfono es obligatorio'),
  email: yupString()
    .email('Correo electrónico inválido')
    .required('El correo electrónico es obligatorio'),
});

import React from 'react';
import { KobrosLogo } from '@fingo/lib/assets';
import { useAvailableHeight } from '@fingo/lib/hooks';
import ProgressiveImageBox from '@fingo/lib/components/boxes/ProgressiveImageBox';
import { CircularProgress, Stack, Typography } from '@mui/material';

const CollectionLoader = () => {
  const [height, refChange] = useAvailableHeight(10);
  return (
    <Stack
      ref={refChange}
      height={height}
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="auto"
      py="5%"
    >
      <svg>
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="20%" stopColor="#6F178A" />
          <stop offset="90%" stopColor="#F7F7F7" />
        </linearGradient>
      </svg>
      <CircularProgress
        size={60}
        sx={{ circle: { stroke: 'url(#linearColors)' } }}
      />
      <Typography
        variant="h6"
        fontWeight={500}
        lineHeight={1.5}
        textAlign="center"
        py={4}
        maxWidth={250}
      >
        Espéranos un momento mientras cargamos todos tus documentos
      </Typography>
      <Stack
        direction="column"
        flexGrow={1}
        alignItems="center"
        justifyContent="flex-end"
      >
        <ProgressiveImageBox
          src={KobrosLogo}
          alt="KobrosLogo"
          sx={{
            maxHeight: 65,
            maxWidth: 'auto',
            objectFit: 'contain',
          }}
        />
        <Typography pt={2} variant="h5">
          Tu asesor de cobranza
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CollectionLoader;

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const CalendarBook = ({ path }) => {
  const embedRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://asset-tidycal.b-cdn.net/js/embed.js';
    script.async = true;
    script.onload = () => {
      if (window.TidyCal && window.TidyCal.init) {
        window.TidyCal.init(embedRef.current);
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="tidycal-embed" data-path={path} ref={embedRef} />;
};

export default CalendarBook;

CalendarBook.propTypes = {
  path: PropTypes.string.isRequired,
};

import React from 'react';
import {
  Grid,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import HandshakeIcon from '@mui/icons-material/Handshake';
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import GroupIcon from '@mui/icons-material/Group';
import DescriptionIcon from '@mui/icons-material/Description';
import WarningIcon from '@mui/icons-material/Warning';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import IconStack from './IconStack';

const CollectionLanding = () => (
  <Grid
    container
    columns={{
      xs: 2,
      md: 3,
    }}
    marginX={{
      xs: 0,
      md: 4,
    }}
    paddingY={2}
    paddingX={{
      xs: 0,
      md: 3,
    }}
    backgroundColor="gray.light"
    borderRadius={10}
    display="flex"
    justifyContent="center"
  >
    <Grid
      item
      xs={1}
      justifyContent="center"
      display="flex"
      width="100%"
    >
      <IconStack Icon={DescriptionIcon} text="Situación financiera de clientes" color="kobros.main" />
    </Grid>
    <Grid
      item
      xs={1}
      justifyContent="center"
      display="flex"
    >
      <IconStack Icon={BarChartIcon} text="Prioridades de cobro" color="kobros.main" />
    </Grid>
    <Grid
      item
      xs={1}
      justifyContent="center"
      display="flex"
    >
      <IconStack Icon={CalendarMonth} text="Ve los plazos de pago promedio" color="kobros.main" />
    </Grid>
    <Grid
      item
      xs={1}
      justifyContent="center"
      display="flex"
    >
      <IconStack Icon={HandshakeIcon} text="Portal de proveedores" color="kobros.main" />
    </Grid>
    <Grid
      item
      xs={1}
      justifyContent="center"
      display="flex"
    >
      <IconStack Icon={EmailIcon} text="Automatiza el envío de mails" color="kobros.main" />
    </Grid>
    <Grid
      item
      xs={1}
      justifyContent="center"
      display="flex"
    >
      <IconStack Icon={BadgeIcon} text="Analiza tus potenciales clientes" color="kobros.main" />
    </Grid>
    <Grid
      item
      xs={1}
      justifyContent="center"
      display="flex"
    >
      <IconStack Icon={BorderColorIcon} text="Deja registro de tus gestiones" color="kobros.main" />
    </Grid>
    <Grid
      item
      xs={1}
      justifyContent="center"
      display="flex"
    >
      <IconStack Icon={GroupIcon} text="Roles y perfiles" color="kobros.main" />
    </Grid>
    <Grid
      item
      xs={1}
      justifyContent="center"
      display="flex"
    >
      <IconStack Icon={WarningIcon} text="Obtén alertas" color="kobros.main" />
    </Grid>
  </Grid>
);

export default CollectionLanding;

import React from 'react';
import PropTypes from 'prop-types';
import { TourGuide } from '@fingo/lib/components/joyRides';
import { TOUR_GUIDE_STEPS } from '@fingo/lib/constants/collection-summary';

const DebtorTourGuide = ({ run, setRun }) => (
  <TourGuide run={run} setRun={setRun} steps={TOUR_GUIDE_STEPS} />
);

DebtorTourGuide.propTypes = {
  run: PropTypes.bool.isRequired,
  setRun: PropTypes.func.isRequired,
};

export default DebtorTourGuide;

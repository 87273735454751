import React from 'react';
import {
  INVOICES_IN_COLLECTION,
} from '@fingo/lib/graphql';
import RoundedWhiteBox from '@fingo/lib/components/boxes/RoundedWhiteBox';
import { useSelectedCompany } from '@fingo/lib/hooks';
import {
  COLUMNS,
  CURRENT_PORTFOLIO_INITIAL_ORDER_BY,
} from '@fingo/lib/constants/collection-summary';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { Typography } from '@mui/material';
import { CURRENT_PORTFOLIO_MINIMAL_HEADERS } from '@fingo/lib/constants';

const InvoicesInCollection = () => {
  const selectedCompany = useSelectedCompany();

  return (
    <RoundedWhiteBox height="100%" width="100%">
      <FingoMainView
        id="invoices-in-collection-list"
        hideReloadButton
        query={INVOICES_IN_COLLECTION}
        queryCustomVariables={{
          inCollection: selectedCompany?.id,
        }}
        slots={{
          header: () => <></>,
          table: FingoDataGrid,
          actions: () => <></>,
        }}
        slotProps={{
          table: {
            noRowsMessage: () => <Typography>Sin clientes</Typography>,
            initialOrderBy: CURRENT_PORTFOLIO_INITIAL_ORDER_BY,
            initialPageSize: 5,
            hideFooter: true,
            includeHeaders: CURRENT_PORTFOLIO_MINIMAL_HEADERS,
            columns: COLUMNS,
          },
          header: {},
          actions: {},
        }}

      />
    </RoundedWhiteBox>
  );
};

export default InvoicesInCollection;

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useIsLogged, useSelectedCompany } from '@fingo/lib/hooks';
import {
  CollectionAccountCreationStep,
  CollectionDemoStep,
  CollectionUploadCredentialsStep,
} from '../components/collection-register';

const useCollectionRegisterSteps = () => {
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const selectedCompany = useSelectedCompany();
  const isLogged = useIsLogged();

  const goNext = () => setActiveStep((prevState) => prevState + 1);

  useEffect(() => {
    if (selectedCompany) {
      history.replace({ pathname: '/app/sales/collection/home' });
    }
  }, [selectedCompany, history]);

  const steps = useMemo(
    () => [
      ...(isLogged
        ? []
        : [
          <CollectionDemoStep goNext={goNext} />,
          <CollectionAccountCreationStep goNext={goNext} />,
        ]),
      <CollectionUploadCredentialsStep />,
    ],
    [],
  );

  return [steps, activeStep];
};

export default useCollectionRegisterSteps;

import React from 'react';
import { MobileStepper } from '@mui/material';
import PilotBackground from '../../../components/pilots/components/PilotBackground';
import useCollectionRegisterSteps from '../hooks/useCollectionRegisterSteps';

const CollectionRegister = () => {
  const [steps, activeStep] = useCollectionRegisterSteps();

  return (
    <PilotBackground>
      <MobileStepper
        steps={steps.length}
        activeStep={activeStep}
        backButton={null}
        nextButton={null}
        position="static"
        variant="dots"
        sx={{
          justifyContent: 'center',
          backgroundColor: 'white',
        }}
      />
      {steps[activeStep]}
    </PilotBackground>
  );
};

export default CollectionRegister;

import React from 'react';
import {
  Typography,
  Stack,
} from '@mui/material';
import propTyes from 'prop-types';

const IconStack = ({ Icon, text, color }) => (

  <Stack
    direction="column"
    marginX={{
      xs: 2,
      md: 5,
    }}
    spacing={1}
    marginY={2}
    width="100%"
  >
    <Icon
      alignItems="center"
      display="flex"
      marginX="auto"
      sx={{
        fontSize: 40,
        marginX: 'auto',
        color,
      }}
    />
    <Typography width="100%" align="center">
      {text}
    </Typography>
  </Stack>
);

IconStack.propTypes = {
  Icon: propTyes.elementType.isRequired,
  text: propTyes.string.isRequired,
  color: propTyes.string.isRequired,
};

export default IconStack;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAlertMessage } from '@fingo/lib/hooks';
import { Dialog, Stack, Typography, Box } from '@mui/material';
import FingoAlert from '@fingo/lib/components/dialogs/alerts/FingoAlert';
import { PropTypes } from 'prop-types';
import KobrosTutorial from '../../../components/buttons/kobros-tutorial';
import { KobrosAskForDemoButton } from '../../../components/buttons';
import { KobrosTooMuchTimeWaiting } from '../../../components/typographys/kobros-action-texts';

const CollectionWaiting = ({
  open,
}) => {
  const history = useHistory();
  const [alertMessage, setAlertMessage] = useAlertMessage();

  return (
    <Dialog open={open} PaperProps={{ borderRadius: '300px' }}>
      <FingoAlert alert={alertMessage} setAlert={setAlertMessage} />
      <Box
        paddingTop={5}
        paddingBottom={3}
        paddingX={{ xs: 5, md: 10 }}
        borderRadius={100}
      >
        <KobrosTooMuchTimeWaiting />
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1.5}
          alignItems="center"
          justifyContent="center"
          marginTop={2.5}
        >
          <KobrosAskForDemoButton text="Solicitar más info" setAlertMessage={setAlertMessage} />
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: null, md: 'space-between' }}
          spacing={1.5}
          marginTop={3.5}
        >

          <Box
            textAlign="center"
            alignItems="center"
            marginY={{ md: 'auto' }}

          >
            <Typography
              variant="caption"
              textAlign="center"
              sx={{ textDecoration: 'underline', color: 'kobros.main', cursor: 'pointer' }}
              onClick={() => history.push('/')}
            >
              Volver al Home
            </Typography>
          </Box>
          <KobrosTutorial />
        </Stack>
      </Box>

    </Dialog>
  );
};

CollectionWaiting.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default CollectionWaiting;

import React from 'react';
import PropTypes from 'prop-types';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { KobrosLogo } from '@fingo/lib/assets';
import ProgressiveImageBox from '@fingo/lib/components/boxes/ProgressiveImageBox';
import { WHATSAPP_LINK } from '../../constants/collection-register';

const CollectionDemoStep = ({ goNext }) => (
  <Stack
    height="100%"
    direction={{ xs: 'column', md: 'row' }}
    alignItems="center"
    justifyContent={{ xs: 'center', md: 'space-around' }}
    pb={10}
    px={{ xs: 4, md: 10 }}
    spacing={2}
    bgcolor={{
      xs: 'fingoWhite.main',
      md: 'inherit',
    }}
  >
    <Stack
      justifyContent="center"
      alignItems={{ xs: 'center', md: 'flex-start' }}
      spacing={4}
      width="auto"
      flex={{ xs: 0.35, md: 1 }}
    >
      <ProgressiveImageBox
        src={KobrosLogo}
        alt="KobrosLogo"
        sx={{
          maxHeight: 65,
          maxWidth: 'auto',
          objectFit: 'contain',
        }}
      />
      <Typography variant="h1" color="secondary">
        Tu mejor asesor de cobranza
      </Typography>
      <Typography variant="body1" color="text">
        En base a algoritmos que consolidan la información de Fingo, koBros te
        entrega información muy valiosa sobre tus clientes; su comportamiento de
        pago, alertas de juicios y otros problemas que pueden afectar tu pago.
      </Typography>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          id="credentials-kobros-contact-executive"
          variant="outlined"
          color="secondary"
          onClick={goNext}
          sx={{
            width: '47%',
            borderRadius: 16,
            fontSize: 12,
          }}
        >
          Registrarme en koBros
        </Button>
        <Button
          id="credentials-kobros-whatsapp-contact"
          variant="contained"
          color="secondary"
          onClick={() => {
            window.open(WHATSAPP_LINK, '_blank');
          }}
          startIcon={<WhatsAppIcon />}
          sx={{
            width: '47%',
            borderRadius: 16,
            fontSize: 12,
          }}
        >
          Contáctanos
        </Button>
      </Stack>
    </Stack>
    <Box flex={1} height="40%" mt={{ xs: 2, md: 0 }}>
      <Paper
        elevation={4}
        sx={{
          py: { xs: 2, md: 3, lg: 5 },
          px: { xs: 3, md: 4, lg: 7 },
          cursor: 'pointer',
        }}
        onClick={goNext}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="primary" fontSize={12} textAlign="center" mb={1}>
              Cliente: Palister
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="text" fontSize={{ xs: 8, sm: 11 }}>
              Tipo de deudor:
            </Typography>
            <Typography
              color="error"
              p={1}
              fontSize={{ xs: 8, sm: 11 }}
              sx={{
                border: '1px solid #000',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                borderRadius: 2,
                bgcolor: 'background.paper',
              }}
            >
              En problemas
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="text" fontSize={{ xs: 8, sm: 11 }}>
              Plazo promedio de pago:
            </Typography>
            <Typography
              color="error"
              p={1}
              fontSize={{ xs: 8, sm: 11 }}
              sx={{
                border: '1px solid #000',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                borderRadius: 2,
                bgcolor: 'background.paper',
              }}
            >
              135 días
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="text" fontSize={{ xs: 8, sm: 11 }}>
              Deuda vigente:
            </Typography>
            <Typography
              color="excel"
              p={1}
              fontSize={{ xs: 8, sm: 11 }}
              sx={{
                border: '1px solid #000',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                borderRadius: 2,
                bgcolor: 'background.paper',
              }}
            >
              $0
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="text" fontSize={{ xs: 8, sm: 11 }}>
              Deuda morosa:
            </Typography>
            <Typography
              color="error"
              p={1}
              fontSize={{ xs: 8, sm: 11 }}
              sx={{
                border: '1px solid #000',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                borderRadius: 2,
                bgcolor: 'background.paper',
              }}
            >
              $1.087.487
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="text" fontSize={{ xs: 8, sm: 11 }}>
              % facturas pagadas después del plazo de pago acordado:
            </Typography>
            <Typography
              color="error"
              p={1}
              fontSize={{ xs: 8, sm: 11 }}
              sx={{
                p: 1,
                border: '1px solid #000',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                borderRadius: 2,
                bgcolor: 'background.paper',
              }}
            >
              100%
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  </Stack>
);

CollectionDemoStep.propTypes = {
  goNext: PropTypes.func.isRequired,
};

export default CollectionDemoStep;

import React, { useMemo } from 'react';
import { hasCollectionAvailable } from '@fingo/lib/helpers/plans';
import { useGetUser } from '@fingo/lib/hooks';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import CollectionSummary from '../components/CollectionSummary';
import CollectionLoader from '../components/CollectionLoader';

const Collection = () => {
  const user = useGetUser();
  const showCollectionTermsAndConditionsView = useMemo(() => !hasCollectionAvailable(user), [user]);

  if (!user || !user.selectedCompany) {
    return <CollectionLoader />;
  }

  if (showCollectionTermsAndConditionsView
  ) { return <Redirect to="/app/sales/collection/collection-landing" />; }
  return <CollectionSummary />;
};

export default Collection;

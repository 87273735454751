import React from 'react';
import {
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import { useGetUser } from '@fingo/lib/hooks';
import { createQueryString } from '@fingo/lib/helpers/text-parser';
import { KobrosAcceptTermsButton, KobrosDownloadGuideButton } from '../../../components/buttons';
import CalendarBook from '../components/CalendarBook';
import { demoUrl } from '../constants/collection';

const CollectionContactUs = () => {
  const user = useGetUser();

  const queryParams = {
    name: user.firstName ? `${user.firstName} ${user.lastName}`.trim() : '',
    email: user.email,
  };

  const path = `${demoUrl}?${createQueryString(queryParams)}`;
  return (
    <Grid container padding={3}>
      <Grid item xs={12} md={5}>
        <Stack spacing={2}>
          <Typography>
            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>Agenda una demo con {' '}
              <Typography sx={{ fontSize: 40, fontWeight: 'bold' }} component="span" color="kobros.main">
                nosotros
              </Typography>
            </Typography>
          </Typography>
          <Typography lineHeight={1.5} variant="h2">
            Elige en el calendario la fecha que más te acomode para aprender a usar koBros.
            Un ejecutivo comercial te explicará cómo nuestro nuevo software te ayuda
            a mejorar la gestión de
            cobranza de tu empresa.
          </Typography>
          <Typography lineHeight={1.5} variant="h2">¡Empieza a usar koBros ya!</Typography>
          <Stack
            sx={{
              paddingTop: 1,
            }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={{
              xs: 2,
              md: 2.5,
            }}
          >
            <KobrosAcceptTermsButton />
            <KobrosDownloadGuideButton text="Manual de uso" />
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
      >
        <CalendarBook
          path={path}
        />
      </Grid>
    </Grid>
  );
};

export default CollectionContactUs;
